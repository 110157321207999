$primary: #3B6EB6;
$secondary-color: #f2e124;
$border-radius: 0.25rem !default;
$fiba-success: #22bb33 !default;
$fiba-secondary: #c9dae1 !default;
$fiba-info: #3c96d7 !default;
$fiba-warning: #f5bd8c !default;
$fiba-danger: #f55d5d !default;
$fiba-dark: #343a40 !default;
$fiba-light: #f8f9fa !default;
$padding-x: 8px !default;
$padding-y: 4px !default;
$drop-hint-arrow-size: 8px !default;
$kendo-input-border-radius: 0.375rem !default;
$kendo-input-padding-y: 4px !default;
$kendo-input-padding-x: 8px !default;
$kendo-input-padding-y-md: 4px !default;
$kendo-input-padding-x-md: 8px !default;
$kendo-input-hover-border: $primary !default;
$kendo-input-focus-shadow: 0 0 0 3px rgba( $kendo-input-hover-border, .1 ) !default;
$kendo-picker-bg: rgb(255, 255, 255);
$kendo-picker-hover-bg: $kendo-picker-bg;
$kendo-picker-focus-shadow: $kendo-input-focus-shadow;
$kendo-picker-focus-border: $kendo-input-hover-border;
$kendo-picker-hover-border: $kendo-input-hover-border;
$kendo-input-placeholder-text: rgb(170, 170, 170);
$window-titlebar-padding-y: 7px !default;
$window-border-radius: 0.5rem !default;
$grid-sorting-indicator-text: #8ba9d4;